import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Category from '../templates/category';

const Products = () => {
  const { allContentfulProduct } = useStaticQuery(graphql`
    query {
      allContentfulProduct {
        nodes {
          title
          description {
            childMdx {
              body
            }
          }
          image {
            fluid(
              maxWidth: 590
              maxHeight: 280
              quality: 100
              cropFocus: CENTER
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  `);

  return <Category pageTitle="Products" data={allContentfulProduct} />;
};

export default Products;
