import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Card from './card';
import { colors } from '../styles/globalStyles';

const Wrapper = styled.section`
  background-color: ${colors.grey};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1em 2em;
`;

const CardContainer = () => {
  const { allContentfulCard } = useStaticQuery(graphql`
    query {
      allContentfulCard(sort: { fields: order }) {
        nodes {
          title
          description {
            raw
          }
          image {
            fluid(
              maxWidth: 590
              maxHeight: 220
              quality: 100
              cropFocus: CENTER
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          url {
            url
          }
        }
      }
    }
  `);

  const { nodes } = allContentfulCard;

  return (
    <Wrapper>
      {nodes &&
        nodes.map(({ title, description, image, url }) => (
          <Card
            key={title}
            title={title}
            description={description}
            image={image}
            url={url.url}
          />
        ))}
    </Wrapper>
  );
};

export default CardContainer;
