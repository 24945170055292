import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { Fade } from 'react-reveal';
import { BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { colors, sizes } from '../styles/globalStyles';

const Wrapper = styled.section`
  padding: 3em 25vw;

  @media (max-width: ${sizes.lg}px) {
    padding: 3em 15vw;
  }

  @media (max-width: ${sizes.md}px) {
    padding: 3em 2em;
  }

  @media (max-width: ${sizes.sm}px) {
    padding: 3em 10px;
  }
`;

const Heading = styled.h2`
  font-weight: 800;
  color: ${colors.black};
  text-align: center;
  letter-spacing: -1px;
  margin: 1.5em;

  @media (max-width: ${sizes.sm}px) {
    font-size: 1.25em;
  }
`;

const Content = styled.p`
  color: ${colors.primary};
  text-align: center;
  line-height: 1.5em;
`;

const About = () => {
  const { contentfulAbout } = useStaticQuery(graphql`
    query {
      contentfulAbout {
        content {
          raw
        }
      }
    }
  `);

  const { content } = contentfulAbout;

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Content>{children}</Content>,
      [BLOCKS.HEADING_2]: (node, children) => <Heading>{children}</Heading>,
    },
  };

  return (
    <Wrapper id="about">
      <Fade>{renderRichText(content, options)}</Fade>
    </Wrapper>
  );
};

export default About;
