import styled, { createGlobalStyle, css } from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

const colors = {
  primary: '#444444',
  highlight: '#ec008c',
  shadow: 'rgba(0, 0, 0, .2)',
  cyan: '#00aeef',
  magenta: '#ec008c',
  darkMageta: '#d1007b',
  yellow: '#fff200',
  grey: '#f3f3f3',
  darkGrey: '#666666',
  black: '#111111',
  white: '#fafafa',
  offWhite: '#aaaaaa',
  blackOpacity: 'rgba(0, 0, 0, .8)',
};

const sizes = {
  xl: 1140,
  lg: 960,
  md: 720,
  sm: 540,
};

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    background: ${colors.grey};
    color: ${colors.primary};
    margin: 0;
    font-size: 1em;
    font-weight: 400;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  a {
    color: ${colors.highlight};
    text-decoration: none;
  }

  p {
    font-weight: 300;
  }

  input, button {
    font-family: inherit;
    font-size: 100%;
    font-weight: 600;
  }
`;

const wrapperStyle = css`
  width: 100%;
  max-width: ${sizes.xl}px;
  margin: 0 auto;
  padding: 0 1.5em;

  @media (max-width: ${sizes.sm}px) {
    padding: 0 10px;
  }
`;

const StyledBackground = styled(BackgroundImage)`
  min-height: 200px;
  height: 20vh;
  background-color: ${colors.blackOpacity};
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 1em;
`;

const Title = styled.h1`
  color: ${colors.white};
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 1.5em;
  text-align: center;
`;

const PageWrapper = styled.section`
  ${wrapperStyle};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: ${sizes.md}px) {
    flex-direction: column;
  }
`;

export {
  colors,
  sizes,
  GlobalStyles,
  wrapperStyle,
  StyledBackground,
  Title,
  PageWrapper,
};
