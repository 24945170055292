import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { Fade } from 'react-reveal';
import { colors, wrapperStyle } from '../styles/globalStyles';

const Container = styled.section`
  background-color: ${colors.primary};
  margin-top: 3em;
  padding: 2em 0;
`;

const Wrapper = styled.div`
  ${wrapperStyle};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Heading = styled.h2`
  color: ${colors.white};
  text-align: center;
`;

const Message = styled.p`
  color: ${colors.offWhite};
  text-align: center;
`;

const QuoteButton = styled(Link)`
  display: block;
  cursor: pointer;
  margin: 2em;
  padding: 1em 1.2em;
  color: ${colors.grey};
  border: 2px solid ${colors.highlight};
  background-color: ${colors.highlight};
  border-radius: 6px;
  letter-spacing: 3px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.5s;

  :hover {
    color: ${colors.white};
    background-color: ${colors.darkMageta};
  }
`;

const ContactBanner = () => {
  const { contentfulContactBanner } = useStaticQuery(graphql`
    query {
      contentfulContactBanner {
        heading
        shortMessage
      }
    }
  `);

  const { heading, shortMessage } = contentfulContactBanner;

  return (
    <Container>
      <Wrapper>
        <Fade>
          <Heading>{heading}</Heading>
          <Message>{shortMessage}</Message>
          <QuoteButton to="/contact">Contact Us</QuoteButton>
        </Fade>
      </Wrapper>
    </Container>
  );
};

export default ContactBanner;
