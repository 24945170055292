import React, { useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import styled from 'styled-components';
import { Fade } from 'react-reveal';
import Layout from '../templates/layout';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3em;
`;

const StyledLink = styled(Link)`
  :hover {
    text-decoration: underline;
  }
`;

const Thanks = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/');
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Layout pageTitle="Thank you" hideContactBanner>
      <Fade>
        <Container>
          <h3>Your inquiry has been submitted.</h3>
          <p>We will get back to you as soon as possible.</p>
          <p>
            Redirecting back to <StyledLink to="/">home</StyledLink> page...
          </p>
        </Container>
      </Fade>
    </Layout>
  );
};

export default Thanks;
