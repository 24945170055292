import React, { useState } from 'react';
import BackgroundImage from 'gatsby-background-image';
import { Link, graphql, useStaticQuery } from 'gatsby';
import styled, { css } from 'styled-components';
import { FaAngleDoubleDown } from 'react-icons/fa';
import { Slide } from 'react-reveal';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { colors } from '../styles/globalStyles';

const StyledBackground = styled(BackgroundImage)`
  min-height: 50vh;
  height: 100vh;
  background-color: ${colors.blackOpacity};
  display: flex;
  justify-content: center;
`;

const CoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2em;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  color: ${colors.white};
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 1.5em;
  text-align: center;
`;

const QuoteButton = styled(Link)`
  display: block;
  cursor: pointer;
  padding: 1em 1.2em;
  color: ${colors.grey};
  border: 2px solid ${colors.highlight};
  background-color: ${colors.highlight};
  border-radius: 6px;
  letter-spacing: 3px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.5s;

  :hover {
    color: ${colors.white};
    background-color: ${colors.darkMageta};
  }
`;

const ArrowContainerStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25vh;
  font-size: 1.5em;
`;

const InvisibleArrow = styled.div`
  ${ArrowContainerStyle};
  visibility: hidden;
`;

const ArrowContainer = styled.div`
  display: block;
  ${ArrowContainerStyle};
`;

const StyledLink = styled.a`
  cursor: pointer;
  color: ${colors.white};
  ${({ isHovered }) => `font-size: ${isHovered ? '0.3em' : '1em'};`};
`;

const Cover = () => {
  const { image, contentfulCover } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "images/cover.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      contentfulCover {
        title
      }
    }
  `);

  const handleArrowClick = () => {
    scrollTo('#about');
  };

  const [isHovered, setIsHovered] = useState(false);
  const toggleHovered = () => setIsHovered(!isHovered);

  return (
    <StyledBackground
      Tag="section"
      fluid={image.childImageSharp.fluid}
      backgroundColor={colors.black}
      preserveStackingContext
    >
      <CoverContainer>
        <InvisibleArrow>
          {isHovered ? <h1>Scroll down</h1> : <FaAngleDoubleDown />}
        </InvisibleArrow>
        <Wrapper>
          <Slide down>
            <Title>{contentfulCover.title}</Title>
          </Slide>
          <Slide up>
            <QuoteButton to="/contact">Request a quote</QuoteButton>
          </Slide>
        </Wrapper>
        <ArrowContainer>
          <StyledLink
            isHovered={isHovered}
            onMouseEnter={toggleHovered}
            onMouseLeave={toggleHovered}
            onClick={handleArrowClick}
          >
            {isHovered ? <Title>Scroll down</Title> : <FaAngleDoubleDown />}
          </StyledLink>
        </ArrowContainer>
      </CoverContainer>
    </StyledBackground>
  );
};

export default Cover;
