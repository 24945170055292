import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { Fade, Slide } from 'react-reveal';
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
  FaCalendarAlt,
} from 'react-icons/fa';
import { colors, sizes } from '../styles/globalStyles';

const Container = styled.section`
  width: 50%;
  flex: 1;
  padding: 2em;
  display: flex;
  flex-direction: column;

  @media (max-width: ${sizes.md}px) {
    width: 100%;
    padding: 2em 0;
  }
`;

const Heading = styled.h2`
  color: ${colors.primary};
  font-size: 1.2em;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5em 0;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.p`
  color: ${colors.primary};
  padding: 0;
  margin: 0.5em;
  font-weight: 600;
  font-size: 0.9em;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const ContactInfo = () => {
  const { contentfulMetadata } = useStaticQuery(graphql`
    query {
      contentfulMetadata {
        mapUrl {
          mapUrl
        }
        addressLine1
        addressLine2
        phoneNumber
        email
        hours
      }
    }
  `);
  const {
    mapUrl,
    addressLine1,
    addressLine2,
    phoneNumber,
    email,
    hours,
  } = contentfulMetadata;

  return (
    <Container>
      <Slide down>
        <Heading>Call or Visit Us</Heading>
      </Slide>
      <Fade>
        <Info>
          <Item>
            <FaMapMarkerAlt />
            <Text>
              {addressLine1}
              <br />
              {addressLine2}
            </Text>
          </Item>
          <Item>
            <FaPhoneAlt />
            <Text>{phoneNumber}</Text>
          </Item>
          <Item>
            <FaEnvelope />
            <Text>{email}</Text>
          </Item>
          <Item>
            <FaCalendarAlt />
            <Text>{hours}</Text>
          </Item>
        </Info>
        <iframe
          title="map"
          src={mapUrl.mapUrl}
          width="100%"
          height="350"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen=""
          aria-hidden="false"
        />
      </Fade>
    </Container>
  );
};

export default ContactInfo;
