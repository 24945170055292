import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styled, { css, keyframes } from 'styled-components';
import { FaBars, FaTimes } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';
import { wrapperStyle, colors, sizes } from '../styles/globalStyles';

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  position: fixed;
  height: auto;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  overflow: hidden;
  background-color: transparent;
  transition: 0.3s;
  ${({ isScrolled }) => `padding-top: ${isScrolled ? '0px' : '60px'}`};

  ${({ isScrolled }) =>
    isScrolled &&
    `
    height: 88px;
    background-color: ${colors.blackOpacity};
    box-shadow: ${colors.shadow} 0 1px 5px 0;
    `};
`;

const Container = styled.nav`
  ${wrapperStyle};
  display: flex;
  justify-content: space-between;
`;

const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ isMobile }) => isMobile && `width: 100%`};
`;

const LogoWrapper = styled.div`
  padding: 1em 0;
  pointer-events: none;
  user-select: none;
`;

const FaButtonStyles = css`
  font-size: 1.5em;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  transition: 0.1s;
`;

const Burger = styled.button`
  ${FaButtonStyles};
  color: ${colors.white};

  :hover {
    color: ${colors.offWhite};
  }
`;

const MobileClose = styled.button`
  ${FaButtonStyles};
  color: ${colors.primary};

  :hover {
    color: ${colors.black};
  }
`;

const MenuBar = styled.ul`
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const MenuItem = styled.li`
  display: inline-block;
  margin-left: 2.5em;
`;

const StyledLink = styled(Link)`
  display: block;
  cursor: pointer;
  white-space: nowrap;
  height: 88px;
  line-height: 88px;
  font-size: 0.85em;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: ${colors.white};
  transition: color 0.1s;

  :hover {
    text-decoration: underline;
    color: ${colors.offWhite};
  }
`;

const MobileContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  pointer-events: all;
  background-color: ${colors.blackOpacity};
  z-index: 999;
`;

const flyOut = keyframes`
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
`;

const SideMenu = styled.div`
  width: 60vw;
  height: 100vh;
  background-color: ${colors.white};
  overflow: hidden;
  padding-top: 2em;
  animation: 0.3s ease-in 0s 1 ${flyOut};

  @media (max-width: ${sizes.md}px) {
    width: 80vw;
  }
`;

const SideBurgerContainer = styled.div`
  width: 100%;
  padding: 0 3em;
  display: flex;
  justify-content: flex-end;
`;

const SideMenuWrapper = styled.div`
  padding: 3em 0;
  display: flex;
  flex-direction: column;
`;

const StyledMobileLink = styled(Link)`
  display: block;
  cursor: pointer;
  padding: 3em;
  width: 100%;
  height: 18px;
  line-height: 18px;
  font-size: 0.9em;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;
  color: ${colors.black};
  transition: color 0.1s;

  :hover {
    text-decoration: underline;
    color: ${colors.primary};
  }
`;

const getWindow = () => {
  if (typeof window !== `undefined`) {
    return window;
  }

  return null;
};

const Header = ({ isHomePage }) => {
  const isMobile = useMediaQuery({ maxWidth: sizes.lg });
  const [isExpanded, setIsExpanded] = useState(false);
  const [isScrolled, setIsScrolled] = useState(!isHomePage);
  const sideMenuRef = useRef(null);

  const activeLinkStyle = { color: colors.offWhite };
  const { site, logo } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          menuLinks {
            name
            to
          }
        }
      }
      logo: file(relativePath: { eq: "images/logo.png" }) {
        childImageSharp {
          fluid(pngQuality: 10) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const { menuLinks } = site.siteMetadata;

  const handleResize = () => {
    setIsExpanded(isMobile && isExpanded);
  };

  const handleClick = e => {
    if (sideMenuRef.current && sideMenuRef.current.contains(e.target)) {
      return;
    }

    setIsExpanded(false);
  };

  const handleScroll = () => {
    const scrollTop =
      typeof document !== `undefined` && document.body
        ? document.body.scrollTop
        : 0;
    setIsScrolled((getWindow()?.pageYOffset || scrollTop) > 0);
  };

  const handleLinkClick = () => {
    setIsExpanded(false);
  };

  useEffect(() => {
    const window = getWindow();
    if (window) {
      window.addEventListener('resize', handleResize);
      window.addEventListener('mousedown', handleClick);

      if (isHomePage) {
        window.addEventListener('scroll', handleScroll, { passive: true });
      }

      return () => {
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('mousedown', handleClick);

        if (isHomePage) {
          window.removeEventListener('scroll', handleScroll, { passive: true });
        }
      };
    }

    return null;
  }, [isMobile]);

  useEffect(() => getWindow()?.scrollTo(0, 0), []);

  return (
    <StyledHeader isScrolled={isScrolled}>
      <Container>
        <LogoContainer isMobile={isMobile}>
          <a href="/" onClick={() => handleLinkClick()}>
            <LogoWrapper>
              <Img
                fluid={logo.childImageSharp.fluid}
                alt="Adequate Digital Image Inc."
                style={{ width: '50vw', maxWidth: '283px' }}
              />
            </LogoWrapper>
          </a>
          {isMobile && (
            <Burger
              isScrolled={isScrolled}
              type="button"
              aria-label="menu"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <FaBars />
            </Burger>
          )}
        </LogoContainer>
        {!isMobile && (
          <MenuBar isExpanded={isExpanded}>
            {menuLinks.map(({ name, to }) => (
              <MenuItem key={name}>
                <StyledLink
                  to={to}
                  activeStyle={activeLinkStyle}
                  $isScrolled={isScrolled}
                  onClick={() => handleLinkClick(to)}
                >
                  {name}
                </StyledLink>
              </MenuItem>
            ))}
          </MenuBar>
        )}
        {isExpanded && (
          <MobileContainer>
            <SideMenu ref={sideMenuRef}>
              <SideBurgerContainer>
                <MobileClose
                  type="button"
                  aria-label="close"
                  isScrolled
                  onClick={() => setIsExpanded(!isExpanded)}
                >
                  <FaTimes />
                </MobileClose>
              </SideBurgerContainer>
              <SideMenuWrapper>
                {menuLinks.map(({ name, to }) => (
                  <StyledMobileLink
                    key={name}
                    to={to}
                    activeStyle={{ color: colors.primary }}
                    onClick={() => handleLinkClick(to)}
                  >
                    {name}
                  </StyledMobileLink>
                ))}
              </SideMenuWrapper>
            </SideMenu>
          </MobileContainer>
        )}
      </Container>
    </StyledHeader>
  );
};

export default Header;

Header.propTypes = {
  isHomePage: PropTypes.bool,
};

Header.defaultProps = {
  isHomePage: false,
};
