import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({ pageTitle }) => {
  const { site, contentfulMetadata } = useStaticQuery(graphql`
    query SEO {
      site {
        siteMetadata {
          siteUrl: url
          image
        }
      }
      contentfulMetadata {
        defaultTitle: title
        description
        keywords {
          keywords
        }
      }
    }
  `);

  const { siteUrl, image } = site.siteMetadata;
  const { defaultTitle, description, keywords } = contentfulMetadata;

  const seo = {
    title: pageTitle || defaultTitle,
    description,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}`,
    keywords: keywords.keywords,
  };

  return (
    <Helmet
      htmlAttributes={{ lang: 'en' }}
      title={seo.title}
      titleTemplate={pageTitle ? `%s | ${defaultTitle}` : ``}
    >
      <meta name="description" content={seo.description} />
      <meta name="keywords" content={seo.keywords} />
      <meta name="image" content={seo.image} />
      <meta property="og:type" content="website" />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
    </Helmet>
  );
};

export default SEO;

SEO.propTypes = {
  pageTitle: PropTypes.string,
};

SEO.defaultProps = {
  pageTitle: null,
};
