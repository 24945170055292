import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styled, { css } from 'styled-components';
import { Fade } from 'react-reveal';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Layout from './layout';
import { colors, sizes } from '../styles/globalStyles';

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    margin: '0.5em',
    padding: theme.spacing(3),
    [theme.breakpoints.down(sizes.md)]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
}));

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3em 0;
`;

const ImgContainer = styled.div`
  width: 100%;
  box-shadow: ${colors.shadow} 0 1px 5px 0;

  @media (min-width: ${sizes.md}px) {
    max-width: 280px;
  }
`;

const ContentContainer = styled.div`
  padding: 0 2em;

  @media (max-width: ${sizes.md}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
`;

const Title = styled.h1`
  font-weight: 800;
  color: ${colors.black};
`;

const Style = css`
  font-weight: 300;
  line-height: 1.5em;
  color: ${colors.primary};
`;

const Paragraph = styled.p`
  ${Style};
`;

const List = styled.ul`
  ${Style};
`;

const Category = ({ pageTitle, data }) => {
  const classes = useStyles();

  return (
    <Layout pageTitle={pageTitle}>
      <Container>
        {data &&
          data.nodes.map(({ title, description, image }, i) => (
            <Paper key={title} className={classes.paper}>
              {image?.fluid && (
                <Fade delay={i * 250}>
                  <ImgContainer>
                    <Img
                      fluid={image.fluid}
                      style={{
                        minWidth: '280px',
                        maxHeight: '280px',
                        borderRadius: '4px',
                      }}
                    />
                  </ImgContainer>
                </Fade>
              )}
              <ContentContainer>
                <Fade delay={i * 250}>
                  <>
                    <Title>{title}</Title>
                    {description?.childMdx?.body && (
                      <MDXProvider components={{ p: Paragraph, ul: List }}>
                        <MDXRenderer>{description.childMdx.body}</MDXRenderer>
                      </MDXProvider>
                    )}
                  </>
                </Fade>
              </ContentContainer>
            </Paper>
          ))}
      </Container>
    </Layout>
  );
};

export default Category;

Category.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};
