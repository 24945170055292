import React, { useState, useEffect } from 'react';
import { FaArrowCircleUp } from 'react-icons/fa';
import scrollTo from 'gatsby-plugin-smoothscroll';
import styled from 'styled-components';
import { colors } from '../styles/globalStyles';

const TopIcon = styled.div`
  position: fixed;
  ${({ scrolled }) => `bottom: ${scrolled ? `0` : `-8em`};`};
  right: 0;
  margin: 2em;
  transition: 0.3s;
`;

const IconButton = styled.a`
  display: block;
  cursor: pointer;
  font-size: 2em;
  color: ${colors.darkGrey};
  transition: 0.3s;

  :hover {
    color: ${colors.magenta};
  }
`;

const BackToTop = () => {
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const scrollTop =
      typeof doucment !== `undefined` && document.body
        ? document.body.scrollTop
        : 0;
    setScrolled(
      ((typeof window !== `undefined` && window.pageYOffset) || scrollTop) > 0
    );
  };

  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.addEventListener('scroll', handleScroll, { passive: true });

      return () => {
        window.removeEventListener('scroll', handleScroll, { passive: true });
      };
    }

    return null;
  });

  const handleClick = () => {
    scrollTo('body');
  };

  return (
    <TopIcon scrolled={scrolled}>
      <IconButton onClick={handleClick}>
        <FaArrowCircleUp />
      </IconButton>
    </TopIcon>
  );
};

export default BackToTop;
