import React from 'react';
import BaseLayout from '../templates/baseLayout';
import Cover from '../components/cover';
import About from '../components/about';
import CardContainer from '../components/cardContainer';
import ContactBanner from '../components/contactBanner';

const Home = () => (
  <BaseLayout isHomePage>
    <Cover />
    <About />
    <CardContainer />
    <ContactBanner />
  </BaseLayout>
);

export default Home;
