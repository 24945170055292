import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Category from '../templates/category';

const Equipments = () => {
  const { allContentfulEquipment } = useStaticQuery(graphql`
    query {
      allContentfulEquipment {
        nodes {
          title
          description {
            childMdx {
              body
            }
          }
          image {
            fluid(
              maxWidth: 590
              maxHeight: 280
              quality: 100
              cropFocus: CENTER
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  `);

  return <Category pageTitle="Equipments" data={allContentfulEquipment} />;
};

export default Equipments;
