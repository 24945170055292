import React from 'react';
import Layout from '../templates/layout';
import ContactInfo from '../components/contactInfo';
import ContactForm from '../components/contactForm';

const Contact = () => (
  <Layout pageTitle="Contact" hideContactBanner>
    <ContactInfo />
    <ContactForm />
  </Layout>
);

export default Contact;
