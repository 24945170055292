import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { Slide, Fade } from 'react-reveal';
import { BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { colors, sizes } from '../styles/globalStyles';

const CardWrapper = styled.div`
  display: flex;
  padding: 1em;
  width: 33.33%;
  transition: transform 0.3s;

  @media (min-width: ${sizes.md}px) {
    max-width: ${sizes.sm}px;
  }

  @media (max-width: ${sizes.md}px) {
    width: 100%;
    padding: 1em 0;
  }

  .gatsby-image-wrapper {
    transition: transform 0.3s;
  }

  :hover {
    transform: translateY(3px);

    .gatsby-image-wrapper {
      transform: scale(1.05);
    }
  }
`;

const CardContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 6px;
  box-shadow: ${colors.shadow} 0 1px 5px 0;
`;

const Box = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  flex: 1 1;
`;

const Title = styled.h2`
  font-weight: 800;
  color: ${colors.black};
  text-align: center;

  :hover {
    color: ${colors.primary};
    text-decoration: underline;
  }
`;

const Description = styled.p`
  color: ${colors.primary};
  text-align: center;
  line-height: 1.5em;
`;

const MoreButton = styled(Link)`
  display: block;
  cursor: pointer;
  padding: 0.8em 1em;
  color: ${colors.highlight};
  border: 1px solid ${colors.highlight};
  background-color: transparent;
  border-radius: 6px;
  font-size: 0.75em;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.3s;

  :hover {
    color: ${colors.white};
    background-color: ${colors.highlight};
  }
`;

const Card = ({ title, description, image, url }) => {
  const { fluid } = image;
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Description>{children}</Description>
      ),
    },
  };

  return (
    <CardWrapper>
      <CardContainer>
        <Box>
          <Fade>
            <Img fluid={fluid} style={{ minHeight: '180px' }} />
          </Fade>
          <Content>
            <div>
              <Slide up>
                <Link to={url}>
                  <Title>{title}</Title>
                </Link>
                {renderRichText(description, options)}
              </Slide>
            </div>
            <Slide up>
              <MoreButton to={url}>View More</MoreButton>
            </Slide>
          </Content>
        </Box>
      </CardContainer>
    </CardWrapper>
  );
};

export default Card;

Card.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.shape({
    raw: PropTypes.string,
  }).isRequired,
  image: PropTypes.shape({
    fluid: PropTypes.object,
  }).isRequired,
  url: PropTypes.string.isRequired,
};
