import React, { useState, useRef } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { Fade, Slide } from 'react-reveal';
import isEmail from 'validator/lib/isEmail';
import Recaptcha from 'react-google-recaptcha';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { sizes, colors } from '../styles/globalStyles';

const { GATSBY_SITE_RECAPTCHA_KEY } = process.env;

const inputStyles = {
  fontFamily: `'Segoe UI', Tahoma, Geneva, Verdana, sans-serif`,
  fontWeight: '600',
  fontSize: '0.9em',
  letterSpacing: '1px',
};

const useStyles = makeStyles(theme => ({
  paper: {
    flex: 1,
    padding: theme.spacing(3),
  },
  form: {
    marginTop: theme.spacing(0),
  },
  grid: {
    marginTop: theme.spacing(3),
  },
  textField: {
    '& input': inputStyles,
    '& textarea': inputStyles,
  },
  label: {
    ...inputStyles,
    textTranform: 'uppercase',
  },
  errorMessage: {
    ...inputStyles,
    color: colors.magenta,
    fontSize: '0.75em',
    paddingTop: theme.spacing(3),
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const Container = styled.section`
  width: 50%;
  flex: 1;
  padding: 2em;
  display: flex;
  flex-direction: column;

  @media (max-width: ${sizes.md}px) {
    width: 100%;
    padding: 2em 0;
  }
`;

const Heading = styled.h2`
  color: ${colors.primary};
  font-size: 1.2em;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

const Button = styled.button`
  cursor: pointer;
  padding: 0.8em 1em;
  color: ${colors.highlight};
  border: 1px solid ${colors.highlight};
  background-color: transparent;
  border-radius: 6px;
  font-size: 0.75em;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.3s;
  outline: none;

  :hover {
    color: ${colors.white};
    background-color: ${colors.highlight};
  }

  :disabled {
    cursor: default;
    color: ${colors.offWhite};
    border: 1px solid ${colors.offWhite};
    background-color: ${colors.grey};
  }
`;

const encode = data =>
  Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join(`&`);

const ContactForm = () => {
  const classes = useStyles();
  const recaptchaRef = useRef();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    subject: false,
    message: false,
  });
  const [valid, setValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const validate = (field, value) => {
    const isError = !value || (field === 'email' && !isEmail(value));

    setErrors({ ...errors, [field]: isError });
    return !isError;
  };

  const handleChange = field => e => {
    const { value } = e.target;

    setValues({ ...values, [field]: value });
    validate(field, value);
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (!loading) {
      setLoading(true);

      const form = e.target;
      const recaptchaValue = recaptchaRef.current.getValue();

      setValid(
        Object.keys(values).every(field => validate(field, values[field]))
      );

      if (!recaptchaValue) {
        setErrorMessage(`Please confirm you're not a robot.`);
        setValid(false);
      }

      if (valid) {
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': form.getAttribute('name'),
            'g-recaptcha-response': recaptchaValue,
            ...values,
          }),
        })
          .then(() => navigate(form.getAttribute('action')))
          .catch(() =>
            setErrorMessage('Unable to submit form. Please give us a call.')
          );
      }
    }
  };

  return (
    <Container>
      <Slide down>
        <Heading>Request a quote</Heading>
      </Slide>
      <Paper className={classes.paper}>
        <Fade>
          <form
            name="contact"
            method="POST"
            action="/thanks"
            data-netlify="true"
            data-netlify-recaptcha="true"
            className={classes.form}
            onSubmit={handleSubmit}
          >
            <TextField
              required
              id="name"
              label="Name"
              name="name"
              variant="outlined"
              margin="normal"
              size="small"
              fullWidth
              className={classes.textField}
              FormHelperTextProps={{ classes: { root: classes.label } }}
              InputLabelProps={{ classes: { root: classes.label } }}
              value={values.name}
              onChange={handleChange('name')}
              error={errors.name}
              helperText={errors.name && 'Name is required'}
            />
            <TextField
              required
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              type="email"
              variant="outlined"
              margin="normal"
              size="small"
              fullWidth
              className={classes.textField}
              FormHelperTextProps={{ classes: { root: classes.label } }}
              InputLabelProps={{ classes: { root: classes.label } }}
              value={values.email}
              onChange={handleChange('email')}
              error={errors.email}
              helperText={errors.email && 'Invalid email'}
            />
            <TextField
              required
              id="subject"
              label="Subject"
              name="subject"
              variant="outlined"
              margin="normal"
              size="small"
              fullWidth
              className={classes.textField}
              FormHelperTextProps={{ classes: { root: classes.label } }}
              InputLabelProps={{ classes: { root: classes.label } }}
              value={values.subject}
              onChange={handleChange('subject')}
              error={errors.subject}
              helperText={errors.subject && 'Subject is required'}
            />
            <TextField
              required
              multiline
              rows="3"
              id="message"
              label="Message"
              name="message"
              variant="outlined"
              margin="normal"
              size="small"
              fullWidth
              className={classes.textField}
              FormHelperTextProps={{ classes: { root: classes.label } }}
              InputLabelProps={{ classes: { root: classes.label } }}
              value={values.message}
              onChange={handleChange('message')}
              error={errors.message}
              helperText={errors.message && 'Message is required'}
            />
            {GATSBY_SITE_RECAPTCHA_KEY && (
              <Grid container justify="center" className={classes.grid}>
                <Recaptcha
                  ref={recaptchaRef}
                  sitekey={GATSBY_SITE_RECAPTCHA_KEY}
                  onChange={() => setValid(true)}
                  onExpired={() => setValid(false)}
                />
              </Grid>
            )}
            <Grid container justify="center" className={classes.grid}>
              <div className={classes.wrapper}>
                <Button type="submit" onChange={handleSubmit} disabled={!valid}>
                  Submit
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Grid>
            <Typography
              align="center"
              paragraph
              className={classes.errorMessage}
            >
              {errorMessage}
            </Typography>
          </form>
        </Fade>
      </Paper>
    </Container>
  );
};

export default ContactForm;
