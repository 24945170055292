import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { GlobalStyles } from '../styles/globalStyles';
import Header from '../components/header';
import SEO from '../components/seo';
import Footer from '../components/footer';
import BackToTop from '../components/backToTop';

const Page = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const BaseLayout = ({ pageTitle, children, isHomePage }) => (
  <Page>
    <SEO pageTitle={pageTitle} />
    <GlobalStyles />
    <Header isHomePage={isHomePage} />
    {children}
    <Footer />
    <BackToTop />
  </Page>
);

export default BaseLayout;

BaseLayout.propTypes = {
  pageTitle: PropTypes.string,
  children: PropTypes.node.isRequired,
  isHomePage: PropTypes.bool,
};

BaseLayout.defaultProps = {
  pageTitle: ``,
  isHomePage: false,
};
