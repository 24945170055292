import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import { sizes, colors, wrapperStyle } from '../styles/globalStyles';
import Logo from '../assets/images/adi.svg';

const Container = styled.footer`
  background-color: ${colors.black};
  color: ${colors.grey};
  flex-grow: 1;
  padding: 3em 0;
`;

const Wrapper = styled.div`
  ${wrapperStyle};
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${sizes.md}px) {
    flex-direction: column;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${sizes.md}px) {
    :first-child {
      align-items: center;
      padding-bottom: 1em;
    }
  }
`;

const FootNote = styled.p`
  font-size: 0.75em;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: ${colors.offWhite};
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.offWhite};
`;

const Text = styled.p`
  padding: 0;
  margin: 0.5em;
  font-weight: 600;
  font-size: 0.75em;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const Footer = () => {
  const { contentfulMetadata } = useStaticQuery(graphql`
    {
      contentfulMetadata {
        addressLine1
        addressLine2
        phoneNumber
        email
      }
    }
  `);
  const { addressLine1, addressLine2, phoneNumber, email } = contentfulMetadata;

  return (
    <Container>
      <Wrapper>
        <Section>
          <Logo width={80} height={32} opacity={0.5} />
          <FootNote>&copy; 2021 Adequate Digital Image Inc.</FootNote>
        </Section>
        <Section>
          <Item>
            <FaMapMarkerAlt />
            <Text>
              {addressLine1}
              <br />
              {addressLine2}
            </Text>
          </Item>
          <Item>
            <FaPhoneAlt />
            <Text>{phoneNumber}</Text>
          </Item>
          <Item>
            <FaEnvelope />
            <Text>{email}</Text>
          </Item>
        </Section>
      </Wrapper>
    </Container>
  );
};

export default Footer;
