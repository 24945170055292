import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Category from '../templates/category';

const Services = () => {
  const { allContentfulService } = useStaticQuery(graphql`
    query {
      allContentfulService {
        nodes {
          title
          description {
            childMdx {
              body
            }
          }
          image {
            fluid(
              maxWidth: 590
              maxHeight: 280
              quality: 100
              cropFocus: CENTER
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  `);

  return <Category pageTitle="Services" data={allContentfulService} />;
};

export default Services;
