import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Fade } from 'react-reveal';
import BaseLayout from './baseLayout';
import ContactBanner from '../components/contactBanner';
import {
  colors,
  StyledBackground,
  Title,
  PageWrapper,
} from '../styles/globalStyles';

const Layout = ({ pageTitle, children, hideContactBanner }) => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "images/cover.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <BaseLayout pageTitle={pageTitle}>
      <StyledBackground
        Tag="section"
        fluid={image.childImageSharp.fluid}
        backgroundColor={colors.black}
        preserveStackingContext
      >
        <Fade>
          <Title>{pageTitle}</Title>
        </Fade>
      </StyledBackground>
      <PageWrapper>{children}</PageWrapper>
      {!hideContactBanner && <ContactBanner />}
    </BaseLayout>
  );
};

export default Layout;

Layout.propTypes = {
  pageTitle: PropTypes.string,
  children: PropTypes.node.isRequired,
  hideContactBanner: PropTypes.bool,
};

Layout.defaultProps = {
  pageTitle: ``,
  hideContactBanner: false,
};
